@import '~app/mixins';

.groups {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 50px;
  align-items: stretch;

  .left {
    width: $left-side-width;
    min-width: $left-side-width;
  }

  .rightSide {
    flex: 1;
    width: calc(100% - 285px); 
  }

  .listingTypes {
    border-bottom: 1px solid $color-border;
    background-color: $color-dirty-white;
    display: flex;
    width: 100%;
    position: relative;

    .typeContainer {
      display: flex;
      padding-left: 7px;
      overflow-x: auto;

      &:last-child {
        flex: 1;

        .padding {
          min-width: 1px;
        }
      }

      &:nth-child(2) {
        padding-left: 0;
      }
    }

    .lmOverlay {
      top: 0;
      bottom: 0;
      right: 0;
      left: 294px;
      padding-left: 275px;
    }
  }

  .dashboard {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(100% - 87px);
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .properties {
      background-color: $color-white;
      box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.37);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-height: 100%;
      flex: 1;

      .header {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        color: $color-grey;
        border-bottom: 1px solid $color-border; // todo remove on static header in table ready
        z-index: 2; // todo remove on static header in table ready
        background: #fff;
        height: 50px;
        position: relative;

        .controls {
          display: flex;
          align-items: center;

          > * {
            margin-left: 12px;
          }

          button {
            min-width: 100px;
            white-space: nowrap;
          }

          .btn {
            min-width: 102px;
            margin-left: 10px;
            line-height: 1;
            font-weight: 400;
            cursor: pointer;

            &.red {
              border-color: $color-red;
              color: $color-red;
            }
          }

          .viewMap {
            display: inline-flex;
            align-items: center;
            margin-right: 15px;
            color: $color-blue-darkest;
            cursor: pointer;

            svg {
              $size: 23px;

              width: $size;
              height: $size;
              margin-right: 11px;
            }
          }

          .importButton svg {
            width: 20px;
          }

          .lmOverlay {
            left: -10px;
            width: 135px;
            top: -8px;
            bottom: 0;
          }
        }

        .caption {
          flex: 1;
          overflow: hidden;
          margin-left: 10px;

          .titleContainer {
            display: flex;
            font-size: 16px;
            line-height: 18px;
            color: $color-blue-dark;
            font-weight: normal;

            .title {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              min-width: 0;
              text-align: right;
            }

            .titleQuantity {
              margin-left: 4px;
            }
          }

          .bottom {
            display: flex;
            justify-content: space-between;
            height: 16px;

            .selected {
              font-style: italic;
              white-space: nowrap;
            }

            .editLink {
              padding: 0;
              font-size: 13px;
            }
          }
        }

        .gridOptions {
          margin: -8px 12px 0 10px;
        }
      }

      .body {
        flex: 1;
        overflow-y: auto;
        margin-top: -1px; // todo remove on static header in table ready

        .selectCell {
          display: flex;
          padding-top: 2px;
          align-items: center;

          &.draggable {
            cursor: grab;
          }

          &.selectHeader {
            justify-content: space-between;

            :global {
              .ag-icon-menu {
                display: none;
                cursor: pointer;
              }
            }

            &:hover {
              :global {
                .ag-icon-menu {
                  display: block;
                }
              }
            }
          }
        }

        .marketingCell {
          &:hover {
            @include primary-color();

            cursor: pointer;
          }
        }

        .detailContainer {
          width: 100%;
          height: 100%;
          background: #f3f5f8;

          .detail {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .detailInner {
              width: 98%;
              height: 98%;

              .actions {
                text-align: center;

                .action {
                  width: 24px;
                  height: 24px;
                  cursor: pointer;
                  display: inline-block;

                  svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-grey-light;
                  }

                  &:hover {
                    svg {
                      @include primary-fill-color();
                    }
                  }
                }
              }
            }

            .postcardContent {
              display: flex;
              justify-content: space-around;
              cursor: pointer;
            }

            .emailContent {
              display: flex;
              justify-content: space-around;
            }

            img {
              height: 100px;
            }

            .btnClose {
              $size: 14px;

              position: absolute;
              right: 17px;
              top: 14px;
              width: $size;
              height: $size;
              padding: 0;

              &:hover {
                opacity: 0.8;
              }

              .iconClose {
                width: $size;
                height: $size;
              }
            }

            .buttons {
              text-align: center;

              button {
                margin-top: 5px;
                width: 130px;
              }
            }
          }
        }

        .link {
          @include primary-color();
        }
      }
    }
  }
}

.map {
  .header {
    display: flex;
    justify-content: space-between;
    margin-top: -15px;
    padding-bottom: 15px;
    height: 50px;
  }

  .headerTitle {
    color: $color-blue-dark;
    font-size: 16px;
    line-height: 18px;
    padding-top: 10px;

    @include text-overflow;
  }

  .buttons {
    margin-right: 25px;
    display: flex;
    align-items: center;

    button {
      margin: 0 10px;
      font-weight: bold;
      font-size: 14px;
    }

    .buttonExport {
      background: none;
      border: none;
      padding: 0;
      margin: 0 15px;

      &:hover {
        opacity: 0.8;
      }
    }

    .iconExport {
      width: 26px;
      height: 24px;
    }
  }

  .modalBody {
    margin-left: -15px;
    margin-right: 15px;
    width: calc(100% + 30px);
    height: calc(100vh - 300px);
  }

  .rowPanels {
    display: flex;
    flex-wrap: nowrap;

    > div {
      margin: 0 2px;
    }
  }
}

:global {
  .property-grid {
    height: calc(100% - 40px);
    .ag-root {
      border-style: none;

      .ag-cell {
        color: $color-grey;
        font-size: 11px;
        padding-left: 6px !important;
        padding-right: 0 !important;

        .ag-icon {
          cursor: pointer;
        }
      }

      .ag-header-cell {
        font-size: 11px;
        padding-left: 6px !important;
        padding-right: 0 !important;

        .ag-header-cell-label {
          .ag-header-cell-text {
            line-height: 12px;
            font-weight: bold;
            white-space: normal;
          }
        }

        .ag-floating-filter-button {
          display: none;
        }

        .ag-floating-filter-body {
          margin-right: 8px;
        }
      }

      .ag-full-width-container {
        .ag-row-hover {
          background-color: unset;
        }

        .ag-react-container {
          height: 100%;

          .ag-row-group {
            background-color: #ececec;
          }
        }
      }
    }

    .ag-status-bar {
      display: none;
    }
  }
}

.groupsContainer:first-child  {
  display: none;
}

:global {
  @media (min-width: 1680px) {
    .property-grid {
      height: calc(100% - 24px);
    }
  } 
}